import React, { useState, useEffect, useRef } from "react";
import "./customerForm.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Customloader from "../../helper/customLoader";

import Modal from "react-bootstrap/Modal";
import LinkExpired from "../../pages/linkExpired"
import CheckoutForm from "./checkoutForm";
import "react-datepicker/dist/react-datepicker.css";
import { ScheduleMeeting, timeSlotDifference } from "react-schedule-meeting";
import { Col, Form, Row, Button } from "react-bootstrap";
import { BsChevronDoubleRight } from "react-icons/bs";
import { Rings } from "react-loader-spinner";
import Select from "react-select";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import { createNotification } from "../../helper/notification";
import * as bookingService from "../../services/bookingServices";
import * as calenderService from "../../services/calenderServices";
import {Link, useParams } from "react-router-dom";
import { postEncryptedData } from "../../services/userServices";
import { getUserServicesById } from "../../services/businessServices";
import * as authServices from "../../services/authServices";
import { ToastContainer } from "react-toastify";
import { getTokens } from "../../helper/firebase";

const imgUrl = process.env.REACT_APP_IMAGE_URL;

const CustomerForm = () => {
  const [tokenFound, setTokenFound] = useState("");
  const [fcmToken, setFCMToken] = useState("");
  const [dbToken, setDbToken] = useState("");
  const [customizeData, setCustomizeData] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const term = queryParams.get("userId");
  const [selectedCountry, setSelectedCountry] = useState("US +1");
  const [selectedBenificialCountry, setSelectedBenificialCountry] =
    useState("US +1");
  const [countryData, setCountryData] = useState([]);
  const reduxToken = useSelector((state) => state?.auth?.token);
  const [data, setData] = useState({
    benificialEmail: "",
    benificialName: "",
    benificialPhone: "",
  });
  const [buttonDisabled, seButtondisabled] = useState(false);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState();
  const [paymentStatus, setpaymentStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState();
  const [startTime, setStartTime] = useState();
  const [name, setName] = useState([]);
  const [service, setService] = useState();
  const [showModal, setShowModal] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailElement, setEmailElement] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [nameElement, setNameelement] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectName, setSelectName] = useState([]);
  const [startDateTime, setStartDateTime] = useState();
  const [serviceSetting, setServiceSetting] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [error, setError] = useState([]);
  const [items, setItems] = useState([]);
  const [activestatus, setActivestatus] = useState(false);
  const [deletedstatus, setDeletedstatus] = useState(false);

  
  
  const [paymentData, setPaymentData] = useState({
    name: "",
    email: "",
  });
  const [smShow, setSmShow] = useState(false);

  const [customloading, setCustomLoding] = useState(true);
  const bookingoptions = [
    { value: "self", label: "Self" },
    { value: "giftcertificate", label: "Gift Certificate" },
  ];
  const typeaheadRef = useRef(null);
  const [stripeData, setStripeData] = useState({
    secretKey: "",
    publicKey: "",
  });
  const Onlinepaymentoptions = [
    customizeData?.Paymentonline === "true" &&
    stripeData.publicKey &&
    stripeData.secretKey
      ? { value: "Paid", label: "Online" }
      : null,
    customizeData?.Paymentoffline === "true"
      ? { value: "UnPaid", label: "Offline" }
      : null,
  ].filter((option) => option !== null);

  const [publicKey, setPublicKey] = useState("");
  const [stripePromise, setStripePromise] = useState(() => loadStripe(publicKey));

  const [selectedOption, setSelectedOption] = useState(bookingoptions[0]);

  const [clientSecret, setClientSecret] = useState("");
  const [servicePrice, setServicePrice] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(() => {
      return {
        ...data,
        [name]: value,
      };
    });
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
    let formattedNumber = value.replace(/[^0-9]/g, "");

    if (name === "benificialPhone") {
      if (formattedNumber.length >= 10) {
        if (formattedNumber.length > 12) {
          formattedNumber = formattedNumber.slice(0, 12);
        }

        const firstThreeDigits = formattedNumber.slice(0, 3);
        const nextThreeDigits = formattedNumber.slice(3, 6);
        const remainingDigits = formattedNumber.slice(6);

        const formattedPhoneNumber = `${firstThreeDigits}-${nextThreeDigits}-${remainingDigits}`;

        setData((prevData) => {
          return {
            ...prevData,
            [name]: formattedPhoneNumber,
          };
        });
      } else {
        setData((prevData) => {
          return {
            ...prevData,
            [name]: formattedNumber,
          };
        });
      }
    }
  };

  const getUser = async () => {
    const userId = term;
    const response = await bookingService.getExternalUser(userId);
    console.log(response,"userIduserIduserId")
    setActivestatus(response?.data.isActivateAccount)
    setDeletedstatus(response?.data.isDeleted)
    if (response.success == true) {
     
      localStorage.setItem("userId", term);
      setStripeData({
        secretKey: response.data.secretKey,
        publicKey: response.data.publicKey,
      });
      setPublicKey(response?.data?.publicKey);
      setFCMToken(response?.data?.fcmToken);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getUser();
  }, [reduxToken]);

  useEffect(() => {
    setStripePromise(loadStripe(publicKey));
  }, [publicKey]);

  const getAllCountryData = async () => {
    const response = await authServices.getCountryData();

    if (response?.status === 200) {
      setCountryData(response?.data);
    }
  };

  useEffect(() => {
    getAllCountryData();
  }, []);

  const handlePyment = (paymentPrice) => {
    handleSubmitPyment(paymentPrice);
  };

  const handleSubmitPyment = async (paymentPrice) => {
    let arr = [];
    items.map((val) => {
      return arr.push(val._id);
    });
    var today = startDate;
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + " " + mm + " " + dd + " " + startTime?.toLocaleTimeString();

    var tomorrow = endDate;
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1;
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    tomorrow = yyyy + " " + mm + " " + dd + " " + endTime?.toLocaleTimeString();
    let timestart = `${moment(startDate).format("YYYY-MM-DD")} ${moment(
      startDate
    ).format("h:mm:ss A")}`;
    let timeend = `${moment(endDate).format("YYYY-MM-DD")} ${moment(
      endTime
    ).format("h:mm:ss A")}`;
    const obj = {
      name: selectName[0]?.name ? selectName[0]?.name : nameElement,
      email: emailElement[0]?.email ? emailElement[0]?.email : emailElement,
      service: arr,
      phoneNumber: phone,
      userId: term,
      servicePrice: paymentPrice,
      token: matchedToken ? matchedToken : "",
      paymentType: selectedPaymentOption?.value,
      availableSlot: slotData,
      startDateTime: startDateTime,
      bookingType: selectedOption.value,
      endDateTime: startDateTime,
      startDate: moment(startDateTime).format("YYYY-MM-DD"),
      endDate: moment(startDateTime).format("YYYY-MM-DD"),
      selectedCountry: selectedCountry,
    };

    const obj1 = {
      name: selectName[0]?.name ? selectName[0]?.name : nameElement,
      email: emailElement[0]?.email ? emailElement[0]?.email : emailElement,
      service: arr,
      phoneNumber: phone,
      userId: term,
      availableSlot: slotData,
      servicePrice: paymentPrice,
      token: matchedToken ? matchedToken : "",
      benificialName: data.benificialName,
      paymentType: selectedPaymentOption?.value,
      benificialEmail: data.benificialEmail,
      benificialPhone: data.benificialPhone,
      startDateTime: startDateTime,
      bookingType: selectedOption.value,
      endDateTime: startDateTime,
      startDate: moment(startDateTime).format("YYYY-MM-DD"),
      endDate: moment(startDateTime).format("YYYY-MM-DD"),
      selectedBenificialCountry: selectedBenificialCountry,
      selectedCountry: selectedCountry,
    };

    setPaymentData({
      name: obj ? obj.name : obj1.name,
      email: obj ? obj.email : obj1.email,
    });
    const Validation = () => {
      let err = {};
      let isValid = true;
      let phoneNo = /^\d{10,15}$/;
      let regex = new RegExp(
        /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
      );

      if (!obj.name) {
        err["name"] = "Customer Name required";
        isValid = false;
      } else if (obj.name.trim() === "") {
        err["name"] = "Customer Name required";
        isValid = false;
      }

      if (!obj.startDateTime) {
        err["startDateTime"] = "Schedule time is required";
        isValid = false;
      }

      if (selectedOption?.value === "giftcertificate") {
        if (!obj1.benificialName.trim()) {
          err["benificialName"] = "Benificial Name  required";
          isValid = false;
        }
      }

      if (!obj.email) {
        isValid = false;
        err["email"] = "Email is required";
      } else if (obj.email.length == 0) {
        isValid = false;
        err["email"] = "Email is required";
      } else if (obj.email.trim() === "") {
        isValid = false;
        err["email"] = "Email is required";
      } else if (!regex.test(obj.email)) {
        isValid = false;
        err["email"] = "Please enter a valid email address";
      }

      if (selectedOption?.value === "giftcertificate") {
        if (!obj1.benificialEmail.trim()) {
          isValid = false;
          err["benificialEmail"] = "Benificial Email is required";
        } else if (!regex.test(obj1.benificialEmail)) {
          isValid = false;
          err["benificialEmail"] =
            "Please enter a valid benificial email address";
        } else if (obj1.benificialEmail.trim() === obj.email.trim()) {
          isValid = false;
          err["benificialEmail"] =
            "Please enter different benificial email address";
        }
      }

      if (!obj.phoneNumber) {
        isValid = false;
        err["phone"] = "Phone Number required";
      } else if (!phoneNo.test(obj.phoneNumber.replace(/-/g, ""))) {
        isValid = false;
        err["phone"] = "Phone number must be of 10-12 digits";
      }

      if (selectedOption?.value === "giftcertificate") {
        if (!obj1.benificialPhone || obj1.benificialPhone.length == 0) {
          isValid = false;
          err["benificialPhone"] = "Benificial Phone Number required";
        } else if (!phoneNo.test(obj1.benificialPhone.replace(/-/g, ""))) {
          isValid = false;
          err["benificialPhone"] =
            "Benificial Phone number must be of 10-12 digits";
        }
      }

      if (obj?.service?.length <= 0) {
        err["service"] = "Service required";
        isValid = false;
      }

      setError(err);
      return isValid;
    };

    if (Validation()) {
      localStorage.setItem("Id", term);
      setIsLoading(true);

      const response = await bookingService?.createExternalBooking(
        selectedOption.value == "self" ? obj : obj1
      );
      if (response?.data?.status == 200) {
        setData({
          benificialName: "",
          benificialEmail: "",
          benificialPhone: "",
        });
        setPhone("");
        setStartDateTime("");
        setpaymentStatus("");
        setNameelement("");
        setEmailElement("");
        setSelectName("");
        setItems([]);
        setStartDate("");
        setStartDate(new Date());
        setEndDate(new Date());
        setStartTime();
        setEndTime();
        setpaymentStatus("");
      } else {
        console.log("error");
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let arr = [];
    items.map((val) => {
      return arr.push(val._id);
    });

    var today = startDate;
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + " " + mm + " " + dd + " " + startTime?.toLocaleTimeString();

    var tomorrow = endDate;
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1;
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    tomorrow = yyyy + " " + mm + " " + dd + " " + endTime?.toLocaleTimeString();
    let timestart = `${moment(startDate).format("YYYY-MM-DD")} ${moment(
      startDate
    ).format("h:mm:ss A")}`;
    let timeend = `${moment(endDate).format("YYYY-MM-DD")} ${moment(
      endTime
    ).format("h:mm:ss A")}`;
    const obj = {
      name: selectName[0]?.name ? selectName[0]?.name : nameElement,
      email: emailElement[0]?.email ? emailElement[0]?.email : emailElement,
      service: arr,
      phoneNumber: phone,
      userId: term,
      paymentType: selectedPaymentOption?.value,
      token: matchedToken ? matchedToken : "",
      availableSlot: slotData,
      startDateTime: startDateTime,
      bookingType: selectedOption.value,
      endDateTime: startDateTime,
      startDate: moment(startDateTime).format("YYYY-MM-DD"),
      endDate: moment(startDateTime).format("YYYY-MM-DD"),
      selectedCountry: selectedCountry,
    };
    const obj1 = {
      name: selectName[0]?.name ? selectName[0]?.name : nameElement,
      email: emailElement[0]?.email ? emailElement[0]?.email : emailElement,
      service: arr,
      phoneNumber: phone,
      userId: term,
      availableSlot: slotData,
      token: matchedToken ? matchedToken : "",
      benificialName: data.benificialName,
      benificialEmail: data.benificialEmail,
      benificialPhone: data.benificialPhone,
      paymentType: selectedPaymentOption?.value,
      startDateTime: startDateTime,
      bookingType: selectedOption.value,
      endDateTime: startDateTime,
      startDate: moment(startDateTime).format("YYYY-MM-DD"),
      endDate: moment(startDateTime).format("YYYY-MM-DD"),
      selectedBenificialCountry: selectedBenificialCountry,
      selectedCountry: selectedCountry,
    };
    setPaymentData({
      name: obj ? obj.name : obj1.name,
      email: obj ? obj.email : obj1.email,
    });
    const Validation = () => {
      let err = {};
      let isValid = true;
      let phoneNo = /^\d{10,15}$/;
      let regex = new RegExp(
        /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
      );

      if (!obj.name) {
        err["name"] = "Customer Name required";
        isValid = false;
      } else if (obj.name.trim() === "") {
        err["name"] = "Customer Name required";
        isValid = false;
      }

      if (!obj.startDateTime) {
        err["startDateTime"] = "Schedule time is required";
        isValid = false;
      }

      if (selectedOption?.value === "giftcertificate") {
        if (!obj1.benificialName.trim()) {
          err["benificialName"] = "Benificial Name  required";
          isValid = false;
        }
      }

      if (!obj.email) {
        isValid = false;
        err["email"] = "Email is required";
      } else if (obj.email.length == 0) {
        isValid = false;
        err["email"] = "Email is required";
      } else if (obj.email.trim() === "") {
        isValid = false;
        err["email"] = "Email is required";
      } else if (!regex.test(obj.email)) {
        isValid = false;
        err["email"] = "Please enter a valid email address";
      }

      if (selectedOption?.value === "giftcertificate") {
        if (!obj1.benificialEmail.trim()) {
          isValid = false;
          err["benificialEmail"] = "Benificial Email is required";
        } else if (!regex.test(obj1.benificialEmail)) {
          isValid = false;
          err["benificialEmail"] =
            "Please enter a valid benificial email address";
        } else if (obj1.benificialEmail.trim() === obj.email.trim()) {
          isValid = false;
          err["benificialEmail"] =
            "Please enter different benificial email address";
        }
      }
      if (!obj.phoneNumber && !obj.selectedCountry) {
        isValid = false;
        err["phone"] = "Phone number and country code required";
      } else if (!obj.phoneNumber) {
        isValid = false;
        err["phone"] = "Phone number required";
      } else if (!obj.selectedCountry) {
        isValid = false;
        err["phone"] = "Country code required";
      } else if (!phoneNo.test(obj.phoneNumber.replace(/-/g, ""))) {
        isValid = false;
        err["phone"] = "Phone number must be of 10-12 digits";
      }
      if (selectedOption?.value === "giftcertificate") {
        if (!obj1.benificialPhone && !obj1.selectedBenificialCountry) {
          isValid = false;
          err["benificialPhone"] =
            "Benificial phone number and country code is required";
        } else if (!obj1.benificialPhone || obj1.benificialPhone.length == 0) {
          isValid = false;
          err["benificialPhone"] = "Benificial phone number required";
        } else if (!obj1.selectedBenificialCountry) {
          isValid = false;
          err["benificialPhone"] = "Country code required";
        } else if (!phoneNo.test(obj1.benificialPhone.replace(/-/g, ""))) {
          isValid = false;
          err["benificialPhone"] =
            "Benificial Phone number must be of 10-12 digits";
        }
      }

      if (obj?.service?.length <= 0) {
        err["service"] = "Service required";
        isValid = false;
      }
      if (!obj?.paymentType) {
        err["paymentType"] = "Payment type required";
        isValid = false;
      }

      setError(err);
      return isValid;
    };
    if (Validation()) {
      if (obj.paymentType === "Paid") {
        setShowModal(true);
      } else {
        setIsLoading(true);
        const response = await bookingService.createExternalBooking(
          selectedOption.value == "self" ? obj : obj1
        );
        if (response?.data?.status == 200) {
          createNotification("success", response?.data?.message);
          setClientSecret(response.data.clientSecret);
          setData({
            benificialName: "",
            benificialEmail: "",
            benificialPhone: "",
          });
          setPhone("");
          setStartDateTime("");
          setpaymentStatus("");
          setNameelement("");
          setEmailElement("");
          setSelectName("");
          setItems([]);
          setStartDate("");
          setStartDate(new Date());
          setEndDate(new Date());
          setStartTime();
          setEndTime();
          setpaymentStatus("");
          setTimeout(() => {
            setIsLoading(false);
            window.location.reload(false);
          }, 200);
        }
        else if (response.status == 400){
          createNotification("warning", "Link is expired");
          setActivestatus(true);
          
          setIsLoading(false);
        }
        else {
          console.log("error");
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getTokens(setTokenFound);
      if (data) {
        setDbToken(data);
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  let matchedToken = null;

  for (const token of fcmToken) {
    if (token === dbToken) {
      matchedToken = token;
      break;
    }
  }

  const { id } = useParams();

  const postEncryptedValue = async (encryptedData) => {
    const resp = await postEncryptedData(encryptedData);
    if (resp.status == 200) {
      if (resp?.data) {
        const resp2 = await getUserServicesById(resp.data);
        let arr = [];
        if (resp2.status == 200) {
          resp2.data.data.map((data) => arr.push(data));
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      postEncryptedValue(id);
    }
  }, [id]);

  const handleSelect = (selectedList, index) => {
    setItems(selectedList);
    setService(selectedList);
  };

  const handleRemove = (selectedList) => {
    setItems(selectedList);
    setService(selectedList);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterBy = () => true;

  useEffect(() => {
    getSchedule();
  }, [reduxToken]);

  const getSchedule = async () => {
    const obj = { userId: term };
    setIsLoading(true);
    const response = await calenderService.getExternalSchedule(obj);
    if (response?.status == 200) {
      let data1 = response?.data?.data[0]?.scheduledData;
      const currentDate = new Date();
      const filteredArray = data1?.filter((obj) => {
        const startDate = new Date(obj.startDate);
        const startOfCurrentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return startDate >= startOfCurrentDate;
      });
      setSchedule(
        filteredArray && filteredArray?.length > 0 ? filteredArray : ""
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getServicesSetting = async () => {
    const obj = { userId: term };
    const response = await bookingService.getExternalServicesSetting(obj);
    console.log(response)
    if (response.status == 200 && response.data?.data.length > 0) {
      const data1 = response?.data?.data[0]?.service;
      setServicePrice(data1);
      const serviceIds1 =
        data1 && data1?.length > 0 && data1?.map((item) => item?.serviceId);
      setServiceSetting(serviceIds1);
    } else {
      console.log("error");
    }
  };

  const getCustomizationData = async () => {
    setCustomLoding(true);
    const obj = { userId: term };
    const response = await bookingService.customizeData(obj);
    if (response?.status == 200 && response?.data?.data?.length > 0) {
      setCustomizeData(response.data?.data[0]);
      setCustomLoding(false);
    } else {
      console.log("error");
      setCustomLoding(false);
    }
  };
  useEffect(() => {
    getCustomizationData();
  }, []);
  useEffect(() => {
    getServicesSetting();
  }, [reduxToken]);

  function test() {
    const filterStartDate = [];
    if (schedule?.length > 0) {
      for (const item of schedule) {
        const currentDate = new Date(item.startDate);
        const year = currentDate.getFullYear();
        const month = currentDate.toLocaleString("default", {
          month: "short",
        });
        const day = currentDate.getDate();
        const startTime = `${day} ${month} ${year} ${item?.startTime}`;
        const endTime = `${day} ${month} ${year} ${item?.endTime}`;

        const dt = { startTime: startTime, endTime: endTime };
        filterStartDate.push(dt);
      }
      return filterStartDate;
    }
  }

  useEffect(() => {
    if (schedule?.length > 0) {
    }
  }, [schedule]);

  const endScheduleTime = new Date(startDateTime);
  endScheduleTime.setMinutes(endScheduleTime.getMinutes() + 30);

  const unavailableTimeSlots = [
    {
      startTime: startDateTime,
      endTime: endScheduleTime,
    },
  ];

  const formattedTimeSlots = unavailableTimeSlots.map((slot) => ({
    startTime: moment(slot.startTime).format("DD MMM YYYY hh:mm:A"),
    endTime: moment(slot.endTime).format("DD MMM YYYY hh:mm:A"),
  }));

  const availableTimeSlotsLessUnavailableTimeSlots = timeSlotDifference(
    test(),
    formattedTimeSlots
  );

  const slotData = [];

  const newSchedule =
    availableTimeSlotsLessUnavailableTimeSlots?.length >= 1
      ? availableTimeSlotsLessUnavailableTimeSlots.map((item) => {
          const startDate = new Date(item?.startTime).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
          const Time = new Date(item?.startTime);
          const changeTime = moment(Time);
          const startTime = changeTime.format("hh:mm:A");

          const Time1 = new Date(item?.endTime);
          const changeTime1 = moment(Time1);
          const endTime = changeTime1.format("hh:mm:A");
          slotData.push({ startDate, startTime, endTime });
        })
      : "";

  const scheduleData =
    schedule?.length > 0
      ? schedule.map((item, id) => {
          const currentDate = new Date(item.startDate);
          const year = currentDate.getFullYear();
          const month = currentDate.toLocaleString("default", {
            month: "short",
          });
          const day = currentDate.getDate();
          const startTime = `${day} ${month} ${year} ${item?.startTime}`;
          const endTime = `${day} ${month} ${year} ${item?.endTime}`;

          return {
            id,
            startTime: startTime,
            endTime: endTime,
          };
        })
      : "";

  const DatePicker = (date) => {
    const selectedTime = moment(date.startTime).format(
      "ddd MMM DD YYYY hh:mm:A:ss [GMT]ZZ (z)"
    );
    setStartDateTime(selectedTime);
  };

  const Item = (prop) => {
    const { data } = prop;
    return (
      <>
        <span
          style={{
            background: "skyblue",
            borderRadius: "12px",
            fontSize: "xx-small",
            padding: "inherit",
          }}
        >
          {data.name ? data.name : ""}
          {setCustomerName(data.name)}
        </span>{" "}
      </>
    );
  };

  const Item2 = (prop) => {
    const { data } = prop;

    return (
      <>
        <span
          style={{
            background: "skyblue",
            borderRadius: "12px",
            fontSize: "xx-small",
            padding: "inherit",
          }}
        >
          {data.email ? data.email : ""}
          {setCustomerEmail(data?.email)}
        </span>{" "}
      </>
    );
  };
  const changeName = async (input) => {
    const userId = term;
    setNameelement(input);
    const response = await bookingService.searchExternalName(input, userId);
    if (response.data.data) {
      const name = response?.data?.data?.map((i) => ({
        id: i._id,
        name: i.name,
        email: i.email,
      }));
      setName(name);
    }
  };

  const handleCountryChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedCountry(selectedOption);
  };

  const handleBenificialCountryChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedBenificialCountry(selectedOption);
  };

  const emailFunc = async () => {
    const res = await bookingService.getExternalCustomerDetails(
      emailElement[0]?.email
    );
    let response = res.data.data;
    if (selectName.length == 0 && emailElement.length == 0) {
      setNameelement("");
    }
    if (res.data.code == 200) {
      setPhone(response[0]?.phoneNumber ? response[0]?.phoneNumber : phone);
    } else {
      console.log("error:");
    }
  };

  useEffect(() => {
    if (emailElement) {
      emailFunc();
    }
  }, [emailElement, selectName]);

  const nameFun = async () => {
    const resp = await bookingService.externalSearchEmailWithName(selectName);
    let response = resp.data.data;
    let arr = [];
    if (response?.length > 0) {
      response.map((data) => arr.push(data));
    }
    if (arr) {
      setEmail(arr);
    }
  };

  useEffect(() => {
    nameFun();
  }, [selectName]);

  const handleChangeEmail = async (term) => {
    setEmailElement(term);
    const response = await bookingService.searchExternalName(term);
    if (response.data.data) {
      const email = response?.data?.data?.map((i) => ({
        id: i._id,
        name: i.name,
        email: i.email,
      }));
      setName(email);
    }
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/[^0-9]/g, "");

    if (sanitizedValue.length >= 10) {
      if (sanitizedValue.length > 12) {
        sanitizedValue = sanitizedValue.slice(0, 12);
      }
      const firstThreeDigits = sanitizedValue.slice(0, 3);
      const nextThreeDigits = sanitizedValue.slice(3, 6);
      const remainingDigits = sanitizedValue.slice(6);

      const formattedPhoneNumber = `${firstThreeDigits}-${nextThreeDigits}-${remainingDigits}`;
      setPhone(formattedPhoneNumber);
    } else {
      setPhone(sanitizedValue);
    }
  };

  function findMatchingPrices(data1, data2) {
    const matchedPrices = [];

    for (const item1 of data1) {
      const idToMatch = item1?.serviceId?._id;
      const matchingItem = data2?.find((item2) => item2?._id === idToMatch);

      if (matchingItem) {
        matchedPrices.push(item1?.price);
      }
    }

    return matchedPrices;
  }

  const matchedData = findMatchingPrices(servicePrice, service);

  const total = matchedData.reduce((acc, price) => acc + price, 0);

  const handlePaymentSelect = (selectedPaymentOption) => {
    setSelectedPaymentOption(selectedPaymentOption);
  };
  const countryDatafunction = () => {
    const countryCode = Object.entries(countryData).map((country) => {
      return (
        <>
          <option key={country} value={country}>
            {country}
          </option>
        </>
      );
    });
    return countryCode;
  };

  return (
    <>
    
      {customloading ? (
        <Customloader />
      ) : activestatus || deletedstatus ?  (
        <LinkExpired />
          ) : (
          <>
          <div className="customer-booking-wrapper">
            <div className="cb-wrap">
              <div className="cb-box">
                <div className="cb-detail">
                  <div className="cb-heading">
                    <div className="logo-circle mb-4">
                      <img
                        src={`${imgUrl}/${customizeData?.logo}`}
                        className="img-fluid"
                        alt="logo"
                      />
                    </div>
                    <h2>{customizeData?.Title}</h2>
                    <p>{customizeData?.Description}</p>
                  </div>
                  <div className="cb-img">
                    <img
                      src={`${imgUrl}/${customizeData?.linkImg}`}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <ToastContainer />
            
              <div
                className="addbooking-form"
                style={{ backgroundColor: `${customizeData?.Theme}` }}
              >
                <div className="revenue-field-wrapper ">
                  <h2>Booking Information</h2>
                  <div className="form-wrapper">
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Add/Search Customer Name</Form.Label>
                            <AsyncTypeahead
                              {...customerName}
                              filterBy={filterBy}
                              id="selections-example"
                              className="topbar-search"
                              isLoading={isLoading}
                              labelKey="name"
                              minLength={1}
                              onChange={(term) => {
                                setEmailElement(term);
                                setSelectName(term);
                                setEmail(term);
                              }}
                              onInputChange={changeName}
                              options={name}
                              selected={selectName ? selectName : ""}
                              placeholder="Search ..."
                              renderMenuItemChildren={(option, props) => (
                                <React.Fragment>
                                  <Item data={option} />
                                </React.Fragment>
                              )}
                            />
                            <span className="error"> {error?.name} </span>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Add / Get Email ID</Form.Label>
                            <AsyncTypeahead
                              {...customerEmail}
                              filterBy={filterBy}
                              id="selections-example"
                              className="topbar-search"
                              isLoading={isLoading}
                              labelKey="email"
                              minLength={1}
                              onInputChange={handleChangeEmail}
                              onChange={(term) => {
                                setEmailElement(term);
                                setSelectName(term);
                                setEmail(term);
                              }}
                              selected={selectName ? selectName : ""}
                              options={name}
                              placeholder="Search ..."
                              ref={typeaheadRef}
                              renderMenuItemChildren={(option, props) => (
                                <React.Fragment>
                                  <Item2 data={option} />
                                </React.Fragment>
                              )}
                            />
                            <span className="error">{error?.email}</span>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <div className="selectinput-field">
                              <Form.Select
                                name="state"
                                onChange={handleCountryChange}
                                value={selectedCountry}
                              >
                                <option value="US,+1">US +1</option>
                                {countryDatafunction()}
                              </Form.Select>
                              <Form.Control
                                type="text"
                                placeholder="Add Phone Number"
                                name="phone"
                                value={phone}
                                onChange={handlePhoneChange}
                              />
                            </div>

                            <span className="error">{error?.phone}</span>
                          </Form.Group>
                        </Col>

                        <Col xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Select Booking Type</Form.Label>

                            <div className="bktype-form">
                              <Select
                                options={bookingoptions}
                                value={selectedOption}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        {selectedOption?.value === "giftcertificate" ? (
                          <>
                            <Col xs={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Benificial Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Benificial Name"
                                  name="benificialName"
                                  value={data.benificialName}
                                  onChange={handleChange}
                                />
                                <span className="error">
                                  {" "}
                                  {error?.benificialName}{" "}
                                </span>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Benificial Email ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Benificial Email Address"
                                  name="benificialEmail"
                                  value={data.benificialEmail}
                                  onChange={handleChange}
                                />
                                <span className="error">
                                  {error?.benificialEmail}
                                </span>
                              </Form.Group>
                            </Col>
                            <Col xs={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Benificial Phone Number</Form.Label>
                                <div className="selectinput-field">
                                  <Form.Select
                                    name="state"
                                    onChange={handleBenificialCountryChange}
                                    value={selectedBenificialCountry}
                                  >
                                    <option value="US,+1">US +1</option>
                                    {countryDatafunction()}
                                  </Form.Select>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    name="benificialPhone"
                                    value={data.benificialPhone}
                                    onChange={handleChangePhone}
                                  />
                                </div>

                                <span className="error">
                                  {error?.benificialPhone}
                                </span>
                              </Form.Group>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        <Col xs={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Service</Form.Label>
                            <Multiselect
                              options={
                                serviceSetting?.length > 0 ? serviceSetting : []
                              }
                              selectedValues={items}
                              onSelect={handleSelect}
                              onRemove={handleRemove}
                              displayValue="service"
                              className="input-control multiselect"
                              name="genres"
                            />
                            <span className="error">{error?.service}</span>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group className="mb-3 select-time">
                            {schedule?.length > 0 ? (
                              <>
                                <Form.Label>Time & Date</Form.Label>
                                <div className="selectdate-field">
                                  <ScheduleMeeting
                                    borderRadius={10}
                                    primaryColor="#3f5b85"
                                    eventDurationInMinutes={30}
                                    availableTimeslots={scheduleData}
                                    onStartTimeSelect={DatePicker}
                                    startTimeListStyle="grid"
                                    filterTime={filterPassedTime}
                                  />
                                </div>
                                <span className="error">
                                  {error?.startDateTime}
                                </span>
                              </>
                            ) : (
                              "No schedule available"
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Payment Type</Form.Label>
                            <Select
                              options={Onlinepaymentoptions}
                              value={selectedPaymentOption}
                              onChange={handlePaymentSelect}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                            <span className="error">{error?.paymentType}</span>
                          </Form.Group>
                        </Col>
                        <div className="submitbtn">
                          <Button
                            className="nextbtn"
                            disabled={!schedule || isLoading ? true : false}
                            type="submit"
                          >
                            {isLoading ? (
                              <>
                                <div className="submit-loader">
                                  <Rings
                                    height="40"
                                    width="40"
                                    radius="10"
                                    color="#ffffff"
                                    wrapperStyle
                                    wrapperClass
                                  />
                                </div>
                              </>
                            ) : (
                              <> Submit</>
                            )}

                            <BsChevronDoubleRight />
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title className="text-center">Pay ${total}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Payment Info</h5>
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    paymentData={paymentData}
                    selectedBenificialCountry={selectedBenificialCountry}
                    selectedCountry={selectedCountry}
                    phone={phone}
                    benificialPhone={data.benificialPhone}
                    service={service}
                    userId={term}
                    paymentType={selectedPaymentOption?.value}
                    handlePyment={handlePyment}
                    clientSecret={clientSecret}
                    servicePrice={servicePrice}
                  />
                </Elements>
              </Modal.Body>
            </Modal>
          </div>
        </>

      )}
    </>
  );
};
export default CustomerForm;